import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1 className='mainName' translate="no">ANPULSE</h1>        
        <span style={{fontSize: "14px", color: "#666"}}>Disponível em breve.</span>
      </header>
    </div>
  );
}

export default App;
